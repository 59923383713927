import dayjs from 'dayjs';

export const reservationDateLimiter = date => {
  // now plus certain limit
  const limit = dayjs().add(1, 'years');
  const selectedDate = dayjs(date, 'YYYY-MM-DD-hh-mm');

  if (selectedDate >= limit) {
    return true;
  } else {
    return false;
  }
};

export const isDateShown = (chosenDate, currentDateFrom) => {
  const now = dayjs();
  const selectedFromDate = dayjs(currentDateFrom, 'YYYY-MM-DD-HH-mm').startOf('day');
  const selectedDate = dayjs(chosenDate);

  if (selectedDate < now || selectedDate < selectedFromDate) {
    return true;
  } else {
    return false;
  }
};
