import toString from 'lodash-es/toString'

export default {
  props: {
    variant: {
      type: Object,
      default: () => ({})
    },
    selectedFilters: {
      default: () => []
    },
    defaultlabel: {
      type: String,
      default: ' * '
    }
  },
  computed: {
    isActive () {
      if (Array.isArray(this.selectedFilters)) {
        return this.selectedFilters.find(f => f.type === this.variant.type)
      }
      const selectedVariantFilter = this.selectedFilters[this.variant.type]
      if (!selectedVariantFilter) return false
      if (Array.isArray(selectedVariantFilter)) return !!selectedVariantFilter.find(variant => variant.id === this.variant.id)
      return toString(selectedVariantFilter.id) === toString(this.variant.id)
    }
  }
}
